
.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: $hr-border-width solid rgba(0,0,0,.1); //$hr-border-color;
}

.vr {
  border-left: $hr-border-width solid $hr-border-color;
  align-self: stretch;
}

.list-inside {
  list-style-position: inside;
}
