.list-group-item {
  text-transform: none;
}

.list-group--accent > .list-group-item {
  border-left: 4px solid transparent;
  border-top-color: $list-group-border-color;
  border-bottom-color: $list-group-border-color;
  padding: 18px 8px;
}

.list-group-flush > :last-child {
  border-bottom: none;
}

.list-group-item:first-child {
  border-radius: unset;
}

.list-group--accent > .list-group-item.active {
  background-color: $gray-200;
  border-left-color: $primary;
  color: $list-group-action-color;
}

.list-group--tight > .list-group-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.list-group-item-action:focus { outline: none; }

.list-group-item a,
.list-group-item.active a {
  text-decoration: none;
}

.list-group-item.active a {
  color: #ffffff;
}