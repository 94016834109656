.desc-list--inline {
  margin-bottom: 0;
  width: 100%;
  overflow-x: hidden;
}

.desc-list--inline > dt {
  display: inline-block;
}

.desc-list--inline > dt:after {
  content: ': ';
}

.desc-list--inline > dd {
  display: inline;
  margin-left: 0.5em;
}

.desc-list--inline-labels > dd:not(:last-child):after {
  display: block;
  content: '';
}

.desc-list--inline-all > * {
  display: inline-block;
}

// .desc-list--inline-all > dd, li {
//   margin-right: 2em;
// }

.desc-list--rewards > dd {
  margin-right: 2em;
}

.desc-list--quiet > dt, .desc-list--quiet-labels > dt {
  font-weight: normal;
}

.desc-list--quiet > dd, li > span {
  color: #999999;
}
