
// This backdrop displays on top of another full screen backdrop. It's meant to be full width and
// have no pointer events, which created an edge case where a user couldn't click and drag
// the scrollbar gutter to scroll the page (https://groupo.visualstudio.com/Ovations_v2/_workitems/edit/30796).
// So, as a workaround, we push this backrop out of the scrollbar space and add a pseudo element that allows
// pointer events to pass through to the scrollbar underneath. The pseudo element has a fixed width, which
// is a little magic number-y, but it's the best course for the structure we have.
$backdrop-SCROLLBAR_OFFSET: 20px;
$backdrop-OVERLAY_FILL: rgba(0, 0, 0, 0.4);

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width:  calc(100% - #{$backdrop-SCROLLBAR_OFFSET});
  height: 100%;
  background-color: $backdrop-OVERLAY_FILL;
  z-index: $zindex-modal-backdrop;
  opacity: 0;
  pointer-events: none;
  transition: opacity 600ms;
}

.backdrop--present {
  pointer-events: auto;
  opacity: 1;
}

.backdrop--present:before {
  content: '';
  display: block;
  height: 100%;
  width: $backdrop-SCROLLBAR_OFFSET;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $backdrop-OVERLAY_FILL;
  z-index: $zindex-modal-backdrop;
  pointer-events: none;
}

.backdrop-content {
  z-index: 1050;
  position: relative;
}
