$floating-sidebar-offset: 206px;

// IE11 requires exact dimensions to achieve the layout
$floating-sidebar-head-height: 43px;
$floating-sidebar-foot-height: 39px;
$floating-sidebar-margin-bottom: 10px;
$floating-sidebar-body-max-height: calc(100vh - #{
  $floating-sidebar-offset
  + $floating-sidebar-head-height
  + $floating-sidebar-foot-height
  + $floating-sidebar-margin-bottom
});

.floating-sidebar {
  position: fixed;
  top: $floating-sidebar-offset;
  width: 250px;
  margin-left: 20px;

  // IE11 will render this incorrectly without these properties.
  overflow: hidden;
  transform: translateZ(0);
}

.floating-sidebar__body {
  overflow: auto;
  max-height: $floating-sidebar-body-max-height;
}
