// Certain FA icons are transparent shapes surrounded by a background,
// e.g. fa-exclamation-circle. Changing the color of these icons impacts
// the surrounding background and not the icon itself, and there is no
// way to adjust each independently, so there's this. If this becomes a common
// thing, would suggest making a mixin to do this.
.fa-exclamation-circle--auxiliary-background {
  position: relative;
}

.fa-exclamation-circle--auxiliary-background:before {
  position: relative;
  z-index: 10;
}

.fa-exclamation-circle--auxiliary-background:after {
  content: '\f111'; // uses fa's own circle icon to match size
  color: #ffffff;
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}