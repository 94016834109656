
.sub-nav__trigger__chevron {
  transition: transform .2s $ovations-ease-in-out;
}

.sub-nav__trigger__chevron.active {
  transform: rotate(-180deg);
}

.sub-nav__item {
  color: $secondary;
  border: none;
  padding-left: 2.5rem;
  padding-right: .8em;

  &:hover {
    color: $secondary;
    background-color: $gray-300;
  }

  &--active, &--active:hover {
    color: $white;
    background-color: $primary;
  }
}