
.bracket {
  position: relative;
}

.bracket__line {
  border: 1px solid currentColor;
  border-top: none;
  height: 8px;
  margin: 8px 0;
}

.bracket__label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: $font-size-sm;
  color: $secondary;
  line-height: 1.2;
}

.bracket__label--narrow {
  width: 130px;
}
