
.badge {
  font-weight: normal;
  font-size: $font-size-sm;
  padding: 0.75rem;
  color: $secondary;
}

.badge--prononunced {
  font-size: $font-size-base;
}

// A special case where we want this element to exactly match the
// height of a select element adjacent to it.
.badge--input-match {
  height: calc(2.25rem + 2px);
}