
.rte {
  background: $input-bg;
  border: $input-btn-border-width solid $input-border-color;
}

.rte .ql-container {
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
}

.rte .ql-toolbar {
  background: none;
  border: none;
}

.rte .ql-toolbar button {
  width: 32px;
  height: 28px;
  border: 1px solid transparent;
}

.rte .ql-toolbar button:hover {
  border-color: $light;
}

.rte .ql-editor {
  border-top: $input-btn-border-width solid $input-border-color;
}

.rte .ql-tooltip { display: none; }

// Validation
.rte-validator {
  display: none;
}

.was-validated .rte-validator:valid ~ .rte {
  border-color: $success;
}

.was-validated .rte-validator:invalid ~ .rte {
  border-color: $danger;
}

.rte.read-only {
  background-color: $input-disabled-bg;
}

.rte.read-only .ql-picker {
  pointer-events: none;
}

// Dropdowns
// Necessary for rendering labels on custom dropdowns
.ql-picker-label:before,
.ql-picker-item:before {
  content: attr(data-label);
}

.ql-insert .ql-picker-label:before {
  content: 'Insert';
}

.ql-insert .ql-picker-label {
  padding-right: 30px; // Moves absolutely positioned dropdown arrow off of text
}
