
.text-body { color: $body-color; }

.text-gray-400 { color: $gray-400; }
.text-gray-700 { color: $gray-700; }

.text-linethrough { text-decoration: line-through; }
.text-underline { text-decoration: underline; }

.text-lh-1 { line-height: 1; }
.text-lh-1\.2 { line-height: 1.2; }
.text-lh-1\.5 { line-height: 1.5; }
.text-lh-2 { line-height: 2; }

.text-bigger { font-size: 1.15em; }
.text-smaller { font-size: 0.75em}
