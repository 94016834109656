
.nav-tabs--light .nav-link {
  background: transparent;
  outline: none;
}

.nav-tabs--light .nav-link.active {
  background: $gray-200;
  border-color: $gray-200;
  font-weight: bold;
}

.nav-tabs--light-inverted,
.nav-tabs--light-inverted .nav-link,
.nav-tabs--light-inverted .nav-link.active {
  border-color: rgba(0, 0, 0, 0.1);
}

.nav-tabs--light-inverted .nav-link {
  cursor: pointer;
  background: $white;
}

.nav-tabs--light-inverted .nav-link.active {
  background: $gray-200;
  border-bottom-color: transparent;
}

.nav-tabs--shrink .nav-item {
  margin-right: 3px;
}
