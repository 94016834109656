
.typeahead-wrapper {
  position: relative;
  max-width: 380px;
}

.typeahead-wrapper__icon {
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.rbt-menu {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.rbt .dropdown-item {
  padding: 0.5rem 1rem;
  white-space: normal;
}
