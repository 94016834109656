/*
 * Panel Set (.panel-set)
 *
 * A set of horizontally layed out independently-scrolling panels.
 */

.panel-set {
  display: flex;
  flex: 1;
}

.panel-set--panel {
  flex: 1;
  overflow: auto;
}

.panel-set--panel--fixed-width {
  flex: 0 0 480px;
}
