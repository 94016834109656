$checkered-light: #e6e6e6;
$checkered-dark: #aaaaaa;

.checkered {
  background-color: $checkered-light;
  background-image: linear-gradient(45deg, $checkered-dark 25%, transparent 25%),
                    linear-gradient(-45deg, $checkered-dark 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, $checkered-dark 75%),
                    linear-gradient(-45deg, transparent 75%, $checkered-dark 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
