.portal {
  height: calc(100vh - #{$ovations-header-height});
  overflow-y: hidden;
}

.portal__main {
  position: relative;
  flex-grow: 1;
}

.portal__edge-fix {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.portal__content {
  overflow-y: auto;
}

.portal__nav {
  min-width: $ovations-sidebar-width;
  max-width: $ovations-sidebar-width;
  overflow-y: auto;
}
