@import "~bootstrap/scss/variables";

$custom-theme-colors:map-merge($theme-colors, (
  hiviz: #ff9900,
  light: #dee2e6, // $gray-300
  success: #28a745,
));

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$tooltip-bg: #343a40; // $gray-800
$tooltip-arrow-color: #343a40; // $gray-800
$enable-validation-icons: false;

$dropdown-border-radius: 0.25rem;
$dropdown-inner-border-radius: calc(0.25rem - 1px);