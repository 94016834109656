.switch {
  display: flex;
  position: relative;
  margin-bottom: $switch-margin-bottom;
}

.slider {
  line-height: 16px;
  flex: 0 0 40px;
  height: 16px;
  cursor: pointer;
  background-color: #ccc;
  transition: 200ms;
  border-radius: 16px;
  margin-right: 12px;
  top: 5px;
  position: relative;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  background-color: white;
  transition: 200ms;
  border-radius: 50%;
  top: -3px;
  box-shadow: 0 0 3px 1px rgba(0,0,0, .3), 0 0 8px 2px rgba(0,0,0, .2);
}

.switch {

  input {
    opacity: 0 !important;
    pointer-events: none;
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:focus + .slider:before {
    box-shadow: 0 0 3px 1px rgba(0,0,0, .3), 0 0 0 7px rgba($primary, 0.2);
  }

  input:checked + .slider:before {
    transform: translateX(100%);
  }

  input:disabled + .slider {
    opacity: 0.5;
    cursor: not-allowed;
  }

  input:disabled ~ .switch__label {
    opacity: 0.5;
  }
}
