.icon-picker {
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  display: inline-block;
  padding: $icon-picker-padding;
}

.icon-picker__options {
  margin-top: #{$icon-picker-tile-gap * -1};
  margin-right: #{$icon-picker-tile-gap * -1};
}

.icon-picker__option {
  display: inline;
  position: relative;
}

.icon-picker__option:nth-child(4n):after {
  content: '\A';
  white-space: pre;
}

.icon-picker__option__input {
  // @include visually-hidden(); expands to:
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.icon-picker__option__display {
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  display: inline-block;
  margin-top: $icon-picker-tile-gap;
  margin-right: $icon-picker-tile-gap;
  padding: $icon-picker-tile-padding;
  color: $text-muted;
}

.icon-picker__option__input:not(:disabled) + .icon-picker__option__display:hover {
  background-color: $gray-200;
}

.icon-picker__option__input:focus + .icon-picker__option__display {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.icon-picker__option__input:checked + .icon-picker__option__display {
  border-color: $primary;
  background: rgba($primary, 0.1);
  color: $dark;
}

.icon-picker__option__input:focus:checked + .icon-picker__option__display {
  box-shadow: 0 0 3px rgba($primary, 0.5);
}

.icon-picker__option__input:not(:disabled) + .icon-picker__option__display {
  cursor: pointer;
}
