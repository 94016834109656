.has-global-nav {
  padding-top: $ovations-header-height;
}

.global-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $ovations-global-nav-depth;
  background: $white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  height: $ovations-header-height;
  display: flex;
  align-items: center;
  padding: 0 $ovations-site-bleed;
}

.global-nav__home-link {
  color: $body-color;
  font-size: $font-size-lg;
  font-style: italic;
  line-height: 1;
}
.global-nav__home-link:hover {
  text-decoration: none;
  color: $body-color;
}

.global-nav__logo {
  width: 128px;
  height: 35.31px;
}

.global-nav__dropdown-menu-scrollY {
  max-height: 75vh;
  overflow-y: auto;
}

