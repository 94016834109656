.pointer--info {
    background-color: #d1ecf1;
    border: none;
    border-radius: 0;
    color: #0c5460;
    margin-left: 30px;
    padding: 0.75rem;
    position: relative;
  }
  
  .pointer--info:before {
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #d1ecf1 transparent transparent;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  