.ratio {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
}

.ratio---8\.5-11 {
  padding-bottom: 129.4117647058824%;
}

.ratio__inner {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
