
.bg-x-light {
  background-color: $gray-200 !important;
}

.bg-gray-400 {
  background-color: $gray-400 !important;
}

.bg-light-blue {
  background-color: #ADC5D9 !important;
}

.bg-light {
  background-color: #dee2e6 !important;
}

.breadcrumb {
  background-color: #fff !important;
}