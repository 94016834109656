
.interactive-icon {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 8px;
  border: 0;
  background: none;
  line-height: 0;
}

.interactive-icon:focus {
  outline: none;
}

.interactive-icon:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  width: 100%;
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: transform 150ms ease-out;
  transform: translate(-50%, -50%) scale(0);
}

.interactive-icon:focus:before {
  transform: translate(-50%, -50%) scale(1);
}
