
.selectable {
  border: none;
  cursor: pointer;
  background: $light;
  padding: $selectable-padding;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  transition: box-shadow 200ms;
}

.selectable:hover,
.selectable:focus {
  outline: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}

.selectable.active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5),
              inset 0 0 0 2px $primary;
}

.selectable--hoverable {
  transition: box-shadow 200ms, background-color 200ms;
  background-color: $white;
}

.selectable--hoverable:hover,
.selectable--hoverable:focus {
  background-color: color-level($primary, -11);
}

.selectable-height {
  height:80px;
}
