
input[type="checkbox"]:not(:disabled),
input[type="radio"]:not(:disabled),
select:not(:disabled) {
  cursor: pointer;
}

.form-control {
  appearance: auto;
}

// For third-party components that do stuff like disabling wrapper divs
.form-control[disabled] {
  background-color: $input-disabled-bg;
}

.form-control--min-110 {
  min-width: 110px;
}

.form-control--h5 {
  // higher specificity to match .h5
  color: $headings-color;
  font-size: $h5-font-size;
  font-weight: 500;
}

.form-control--invisalign {
  border-color: transparent;
  border-left-width: 0;
  border-right-width: 0;
  padding-left: 0;
}

.text-center > .form-check-input {
  position: absolute;
}
// Improves alignment of radios and checkboxes when you have embiggened labels.
// Note that this is meant to work with Reactstrap's implementation of Bootstrap controls, yet
// the current version of Bootstrap uses generated content for these controls, so there is drift
// between the two implementations.
.switch > .form-check-input {
  width: 0;
}

.form-check-input--v-align {
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 15px;
}

.form-control--invisalign[readonly] {
  background-color: inherit;
}

.legend--modest {
  font-size: 1rem;
}

.was-validated .is-invalid ~ .invalid-feedback,
.was-validated .is-invalid .invalid-feedback {
  display: block;
}

.was-validated .checkbox-group .invalid-feedback {
  margin-left: -1.25rem; // -#{$form-check-input-gutter};
}

.validatable:not(.was-validated) .validation-status {
  display: none;
}

.input-group {
  flex-wrap: wrap;
}
.input-group .invalid-feedback {
  flex-basis: 100%;
}

.input-group--raised {
  border: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.input-group--raised input {
  border: 0;
}

.input-group--raised .input-group-addon {
  background-color: $white;
  border: 0;
}

// re-adding multiple form classes that were removed in bootstrap 5
.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}