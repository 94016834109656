
.clamp {
  position: relative;
  overflow: hidden;
}

.clamp:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: $line-height-base * 1em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.clamp-2 {
  height: #{$line-height-base * 2em};
}
