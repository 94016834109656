
.card .close {
  outline: none; // It has a hover and focus state already
  cursor: pointer;
}

.card--accent {
  border-left: solid 5px $primary;
}

.card--dashed {
  border: 1px solid transparent;
  border-radius: 0;
  border-style: dashed;
}

.card--dashed-focus,
.card--dashed:focus,
.card--dashed:hover {
  background-color: rgba(0, 0, 0, 0.03125);
  border-color: rgba(0, 0, 0, 0.375);
}

.card--dashed--primary {
  border-color: $primary;
}

@each $color, $value in $theme-colors {
  .card--accent--#{$color} {
    border-left-color: $color;
  }
}

.card__notification {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;
  background: $white;
}

.card--dashed:focus > .card__notification,
.card--dashed:hover > .card__notification {
  display: none;
}

.card .card__reveal {
  visibility: hidden;
}

.card--dashed:focus .card__reveal,
.card--dashed:hover .card__reveal {
  visibility: visible;
}

.card--concealed:focus .card__reveal,
.card--concealed:hover .card__reveal {
  opacity: 0;
}

.card--concealed > .card-body {
  height: 400px;
  overflow: hidden;
  pointer-events: none;
}
