
.has-client-nav {
  padding-left: $ovations-sidebar-width;
}

.has-portal-nav {
  overflow: hidden;
}

.client-nav {
  position: fixed;
  z-index: $ovations-client-nav-depth;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: $ovations-header-height;
  width: $ovations-sidebar-width;
  background: $white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.client-nav__inner {
  flex-grow: 1;
  overflow: auto;
}

.client-nav__client-logo {
  flex: 0 0 $ovations-sidebar-logo-height;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.client-nav__primary-logo {
  // Hardcoded dimensions necessary for IE11 to render the image's container correctly and the
  // image without skewing.
  width: 168px;
  height: 46px;
}

.client-nav__dropdown-toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
}

.client-nav__dropdown-menu {
  min-width: 100%;
}

.client-nav__dropdown-menu-scrollY {
  max-height: 75vh;
  overflow-y: auto;
}