
.subtle-input {
  padding-left: 5px;
  margin-left: -5px;
}

.subtle-input:not(:focus, :hover),
.subtle-input:disabled,
.subtle-input[readonly] {
  background-color: transparent;
  border-color: transparent;
}
