
.table {
  position: relative;
}

.table__spinner {
  position: absolute;
  width: 100%;
  display: none;
}

.table--loading .table__spinner {
  display: block;
}

.table--loading tbody {
  opacity: 0.5;
}

.table__cell--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}

.table tbody th {
  font-weight: normal;
}

.table__row--header {
  font-weight: bold;
  background-color: #ECECEC;
}
