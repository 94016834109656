
.modal .close {
  cursor: pointer;
  color: $white;
  opacity: 0.7;
}

.modal .close:not(.disabled):hover {
    opacity: 1;
}

.modal-header {
  background-color: $primary;
  color: $white;
}

.modal-header--primary {
  background-color: $primary;
  color: $white;
}

.modal-header--light {
  background: $light;
}

.modal-content {
  word-break: break-word;
}

.modal-footer {
  overflow: hidden;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

// Nearly fullscreen takeover modal
.modal-dialog--takeover {
  max-width: none;
  height: 100%;
  margin: 0;
  padding: $modal-dialog-margin;
}

.modal-dialog--takeover .modal-content {
  height: 100%;
}

.modal-dialog--takeover .modal-body {
  overflow: auto;
}

.modal-dialog--takeover .modal-header,
.modal-dialog--takeover .modal-footer {
  flex-shrink: 0;
}

.modal-dialog--takeover--thin {
  max-width: 840px;
  height: 100%;
  margin: 0 auto;
  padding: $modal-dialog-margin;
}

.fullscreen {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
