
// default button text to uppercase per ux direction
.btn {
  text-transform: uppercase;
}

.btn-link {
  text-transform: none;
}

.btn--soft {
  text-transform: none;
}

.btn:not(:disabled) {
  cursor: pointer;
}

.btn--blend-in {
  background: none;
  border: none;
  text-transform: none;
}

.btn--tableHeader {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: .5rem; // $table-cell-padding;
  text-align: left;
  width: 100%;
  text-transform: none;
}

.btn-hiviz {
  color: white;
}

.btn-light {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-light:hover {
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}

.btn-x-light {
  background: none;
  border-color: transparent;
  color: $dark;
}

.btn-x-light:hover:not(:disabled) {
  background: $light;
  border-color: $light;
}

.btn-group > .btn + .btn:not(.btn-outline) {
  margin-left: 1px;
}

.btn-border {
  border-color: #cec8c8 !important;
  border-radius: 2px;
}

.btn:focus, .btn.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.btn-close {
  background: transparent;
}

.btn-success {
  color: #ffffff;
}

.btn-success:hover {
  color: #ffffff;
}