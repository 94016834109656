body {
  overflow: auto;
}

.emboss {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1);
}

.dragging {
  box-shadow: $ovations-drop-shadow-1;
  z-index: #{$zindex-tooltip + 1};
}

.grabbable,
.btn.grabbable:not(:disabled) {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.grabbable:active,
.btn.grabbable:not(:disabled):active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.gradient {
  background: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.9));
}

.overflow-hidden {
  overflow: hidden;
}

.prepend {
  padding-left: 24px;
  position: relative;
}

.prepend__item {
  left: 0;
  position: absolute;
}

.will-raise {
  box-shadow: none;
  transition: box-shadow 400ms;
}

.raised,
.raise-remote .raise-target /* Useful for SortableList's helperClass */ {
  box-shadow: $ovations-drop-shadow-1;
}

.rectified {
  border-radius: 0;
}

.scaled-67 {
  transform: scale(0.67, 0.67);
  transform-origin: top left;
  width: 150%;
}

.secret-trigger:not(:hover, :focus) .secret-trigger__secret { visibility: hidden; }

.zindex-popover {
  z-index: $zindex-popover;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.width-unset {
  width: unset !important;
}