
@keyframes spin {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

.spin {
  animation: 1s $ovations-ease-in-out spin infinite;
}

.spin--continuous {
  animation-timing-function: linear;
  animation-duration: 500ms;
}
